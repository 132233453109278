////////////////////////////////////////////////////////////
//     							                          //
//  Program: Properties.jsx                                    //
//  Application: Properties                                    //
//  Option: List all Properties                                //
//  Developer: Ashish Kumar,NP ,PS 						                 //
//  Date: 2022-01-24                                           //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import PropertyService from "../../services/PropertyService";
import BrandService from "../../services/BrandService";
import CountryService from "../../services/CountryService";
import CountyService from "../../services/CountyService";
import CityService from "../../services/CityService";
import StateService from "../../services/StateService";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Modal,
  InputGroup,
  Form,
  Nav,
  Tab,
} from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import filterIcon from "./../../assets/images/filter-gray.svg";
import propertyLogo from "./../../assets/images/property_placeholder.png";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import NoData from "../common/NoData";
import queryString from "query-string";
import { FileUploader } from "react-drag-drop-files";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import BuildingOnboardingService from "../../services/BuildingOnboardingService";
import infoIcon from "../../assets/images/info-b.svg";

let queryParams = queryString.parse(window.location.search);

export class Properties extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      property_brand_id: "",
      importModal: false,
      file_url: "",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      module_search: "",
      is_search: 0,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "property_logo",
          text: "Property Logo",
          className: "property_logo hand-cursor",
          sortable: false,
          cell: (records) => {
            return (
              <img
                src={
                  records.property_logo_thumb_url
                    ? records.property_logo_thumb_url
                    : propertyLogo
                }
                className="brand_list_image"
                alt={records.property_name}
                onClick={(e) => this.rowClickedHandler(e, records.slug)}
              />
            );
          },
        },
        {
          key: "property_name",
          text: "Property Name/DBA",
          className: "property_name hand-cursor",
          sortable: true,
          cell: (records) => {
            return (
              <p
                className="m-0"
                onClick={(e) => this.rowClickedHandler(e, records.slug)}
              >
                {records.property_name ? records.property_name : ""}{" "}
              </p>
            );
          },
        },
        {
          key: "aka_name",
          text: "Property SKU",
          className: "aka_name hand-cursor",
          sortable: true,
          cell: (records) => {
            return (
              <p
                className="m-0"
                onClick={(e) => this.rowClickedHandler(e, records.slug)}
              >
                {records.aka_name ? records.aka_name : ""}
              </p>
            );
          },
        },
        {
          key: "brand_id",
          text: "Brand Name",
          className: "brand_name hand-cursor",
          sortable: true,
          cell: (records) => {
            return (
              <p
                className="m-0"
                onClick={(e) => this.rowClickedHandler(e, records.slug)}
              >
                {records.brand ? records.brand.brand_name : ""}
              </p>
            );
          },
        },
        {
          key: "property_phone_number",
          text: "Phone",
          className: "property_phone_number hand-cursor",
          sortable: true,
          cell: (records) => {
            var lastTenDigits = "";
            const cleanedNumber = records.property_phone_number
              ? records.property_phone_number.replace(/[\s-]/g, "")
              : [];
            if (cleanedNumber.length > 10) {
              // If it's longer, keep only the last 10 characters
              lastTenDigits = cleanedNumber.slice(-10);
            } else {
              // If it's 10 or less characters, set it as is
              lastTenDigits = records.property_phone_number
                ? records.property_phone_number
                : [];
            }

            var number = records.property_phone_number
              ? lastTenDigits.trim().replace(/[^0-9]/g, "")
              : "";
            var finalNumber = "";
            if (number.length < 4) {
              finalNumber = number;
            } else if (number.length < 7) {
              finalNumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
            } else if (number.length < 11) {
              finalNumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            } else {
              finalNumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
            }
            return (
              <p
                className="m-0"
                onClick={(e) => this.rowClickedHandler(e, records.slug)}
              >
                {records.property_phone_number ? finalNumber : ""}
              </p>
            );
          },
        },
        {
          key: "property_email",
          text: "Email",
          className: "property_email hand-cursor",
          sortable: true,
          cell: (records) => {
            return (
              <p
                className="m-0"
                onClick={(e) => this.rowClickedHandler(e, records.slug)}
              >
                {records.property_email ? records.property_email : ""}
              </p>
            );
          },
        },
        {
          key: "property_manager",
          text: "Property Manager",
          className: "property_manager hand-cursor",
          sortable: false,
          cell: (records) => {
            return (
              <p
                className="m-0"
                onClick={(e) => this.rowClickedHandler(e, records.slug)}
              >
                {records.property_manager_info &&
                records.property_manager_info.first_name
                  ? records.property_manager_info.first_name +
                    (records.property_manager_info.last_name
                      ? " " + records.property_manager_info.last_name
                      : "")
                  : ""}
              </p>
            );
          },
        },
        {
          key: "property_manager_phone_number",
          text: "Property Manager Phone",
          className: "property_manager_phone_number hand-cursor",
          sortable: false,
          cell: (records) => {
            var lastTenDigits = "";
            const cleanedNumber =
              records.property_manager_info &&
              records.property_manager_info.phone_number
                ? records.property_manager_info.phone_number.replace(
                    /[\s-]/g,
                    ""
                  )
                : [];
            if (cleanedNumber.length > 10) {
              // If it's longer, keep only the last 10 characters
              lastTenDigits = cleanedNumber.slice(-10);
            } else {
              // If it's 10 or less characters, set it as is
              lastTenDigits =
                records.property_manager_info &&
                records.property_manager_info.phone_number
                  ? records.property_manager_info.phone_number
                  : [];
            }

            var number =
              records.property_manager_info &&
              records.property_manager_info.phone_number
                ? lastTenDigits.trim().replace(/[^0-9]/g, "")
                : "";
            var finalnumber = "";
            if (number.length < 4) {
              finalnumber = number;
            } else if (number.length < 7) {
              finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
            } else if (number.length < 11) {
              finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            } else {
              finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
            }
            return (
              <p
                className="m-0"
                onClick={(e) => this.rowClickedHandler(e, records.slug)}
              >
                {records.property_manager_info &&
                records.property_manager_info.phone_number
                  ? finalnumber
                  : ""}
              </p>
            );
          },
        },
        // {
        //   key: "property_manager_email",
        //   text: "Property Manager Email",
        //   className: "property_manager_email hand-cursor",
        //   sortable: false,
        //   cell: (records) => {
        //     return records.property_manager_info &&
        //       records.property_manager_info.email ? (
        //       // <Link to={"/brand/view/" + records.slug + "/brand-info"}>
        //       <p onClick={(e) => this.rowClickedHandler(e, records.slug)}>
        //         {records.property_manager_info.email}
        //       </p>
        //     ) : (
        //       // </Link>
        //       ""
        //     );
        //   },
        // },
        {
          key: "property_notes",
          text: "Notes",
          className: "property_notes",
          sortable: false,
          cell: (records) => {
            return records.property_notes ? (
              <span className="d-inline-flex align-items-start justify-content-start">
                <img
                  width="15px"
                  src={infoIcon}
                  className="mg-l-10 hand-cursor"
                  alt="Info icon"
                  onClick={(e) => {
                    if (records.property_notes) {
                      this.openNotesPopup(e, records.property_notes);
                    }
                  }}
                />
              </span>
            ) : (
              ""
            );
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id",
          sortable: false,
          cell: (records) => {
            return records.status_id === 1 ? (
              <b className="status-active">Active</b>
            ) : (
              <b className="status-inactive">InActive</b>
            );
          },
        },
      ],
      brandList: [],
      newBrandList: [],
      selectedBrandList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      selectedCountyList: [],
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      brand_id: "",
      brandListTotal: 0,
      countryListTotal: 0,
      stateListTotal: 0,
      isSearch: global.isSearch,
      tab: "active",
      PropertyTabData: localStorage.getItem("property_tab_data")
        ? localStorage.getItem("property_tab_data")
        : "",
      activeClass: "activeTab",
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      selectedStatusList: [],
      menuPermission: localStorage.getItem("roleList")
        ? JSON.parse(localStorage.getItem("roleList"))
        : [],
      notesPopup: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetData = this.resetData.bind(this);
    this.exportProperty = this.exportProperty.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.searchToggle = this.searchToggle.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.confirmImport = this.confirmImport.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.openNotesPopup = this.openNotesPopup.bind(this);
  }

  componentDidMount() {
    if (queryParams.isGlobal === "1") {
      if (document.querySelector("#left-tabs-example-tabpane-activeTab")) {
        document
          .querySelector("#left-tabs-example-tabpane-activeTab")
          .classList.add("active");
        document
          .querySelector("#left-tabs-example-tabpane-activeTab")
          .classList.add("show");
      }
      this.getData(
        "is_search=1&tab=" +
          this.state.PropertyTabData +
          "&" +
          queryParams.global_col_name +
          "=" +
          queryParams.global_search_value
      );
    } else {
      switch (this.state.PropertyTabData) {
        case "active":
          document.getElementById("activeTab").click();
          break;
        case "inactive":
          document.getElementById("inactiveTab").click();
          break;
        default:
          document.getElementById("activeTab").click();
      }
    }
  }

  openNotesPopup(e, property_notes) {
    this.setState({ notesPopup: true, property_notes: property_notes });
  }

  closeNotesPopup() {
    this.setState({ notesPopup: false, property_notes: "" });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      this.getData("tab=active");
      setTimeout(() => {
        localStorage.setItem(
          "property_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactiveTab") {
      this.setState({ tab: "inactive" });
      this.getData("tab=inactive");
      setTimeout(() => {
        localStorage.setItem(
          "property_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  async getData(queryString = "") {
    localStorage.setItem("propertyLockType", "");
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;

    let res = await PropertyService.getPropertyList(
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      // totalRecords = list ? list.length : 0;
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  async getOnboardingSetpsCount(slug) {
    var totalCount = 0;
    var setpsCount = 0;
    if (slug) {
      let res = await BuildingOnboardingService.getOnboardingStepsCount(slug);
      if (global.successStatus.includes(res.status)) {
        totalCount = res.data ? res.data.totalCount : 0;
        setpsCount = res.data ? res.data.setpsCount : 0;
        if (
          this.state.menuPermission &&
          this.state.menuPermission.length > 0 &&
          this.state.menuPermission.some((obj) => obj.roleId === 2) &&
          this.state.totalCount !== (this.state.setpsCount ?? 0)
        ) {
          this.props.history.push(
            "/properties/view/" + slug + "/building-onboarding"
          );
        } else {
          this.props.history.push(
            "/properties/view/" + slug + "/property-overview"
          );
        }
      }

      this.setState({
        totalCount: totalCount,
        setpsCount: setpsCount,
        menuPermissonLoad: true,
      });
    }
  }

  clearFilter() {
    if (this.state.property_brand_id) {
      this.setState({
        property_brand_id: "",
      });
      this.setState({ module_search: "", is_search: 0 });
    } else {
      queryParams = {};
      this.setState({ is_search: 0 });
      this.setState({
        property_name: "",
        property_manager_name: "",
        zipcode: "",
        city_name: "",
        city_slug: "",
        state_slug: "",
        state_name: "",
        aka_name: "",
        property_email: "",
        property_phone_number: "",
        brand_id: "",
        status_id: "",
        selectedBrandList: [],
        selectedStateList: [],
        selectedCityList: [],
        selectedStatusList: [],
      });
      this.setState({ module_search: "" });
      this.getData("tab=" + this.state.tab);
    }
  }
  onChangePhone(value, country, e, formattedValue) {
    this.setState({ property_phone_number: value });
    this.setState({ dial_code: country.dialCode });
  }
  // async getBrandData(queryString = "") {
  //   this.setState({ showSpinner: true });
  //   var brandList = [];

  //   let res = await BrandService.getBrandList(
  //     "is_dropdown=1" + (queryString ? "&" + queryString : "")
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     brandList = res.data ? res.data : [];
  //   }
  //   this.setState({ brandList: brandList });
  //   this.setState({ showSpinner: false });
  // }

  // async getCountryData(queryString = "") {
  //   this.setState({ showSpinner: true });
  //   var countryList = [];

  //   let res = await CountryService.getCountry(
  //     "is_dropdown=1" + (queryString ? "&" + queryString : "")
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     countryList = res.data ? res.data : [];
  //   }
  //   this.setState({ countryList: countryList });
  //   this.setState({ showSpinner: false });
  // }

  // async getCityData(value, queryString = "") {
  //   // this.setState({ showSpinner: true });
  //   var cityList = [];

  //   let res = await CityService.getCity(
  //     "is_dropdown=1&state_slug=" +
  //     value +
  //     (queryString ? "&" + queryString : "")
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     cityList = res.data ? res.data : [];
  //   }
  //   this.setState({ cityList: cityList });
  //   // this.setState({ showSpinner: false });
  // }

  // async getStateData(countryValue, queryString = "") {
  //   // this.setState({ showSpinner: true });
  //   var stateList = [];

  //   let res = await StateService.getStates(
  //     "is_dropdown=1&country_slug=" +
  //     countryValue +
  //     (queryString ? "&" + queryString : "")
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     stateList = res.data ? res.data : [];
  //   }
  //   this.setState({ stateList: stateList });
  //   // this.setState({ showSpinner: false });
  // }

  // async getBrandData(queryString = "") {
  //   this.setState({ showSpinner: true });
  //   var brandList = [];

  //   let res = await BrandService.getBrandList(
  //     "is_dropdown=1" + (queryString ? "&" + queryString : "")
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     brandList = res.data ? res.data : [];
  //   }
  //   this.setState({ brandList: brandList });
  //   this.setState({ showSpinner: false });
  // }

  // add by saurabh shukla
  async getBrandData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var brandList = [];
    var brandListTotal = 0;
    let res = await BrandService.getBrandList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      brandList = res.data ? res.data : [];
      brandListTotal = brandList ? brandList.length : 0;
    }
    const newBrandList =
      brandListTotal > 0
        ? brandList.map(({ slug, brand_name }) => ({
            value: slug,
            label: brand_name,
          }))
        : [];
    this.setState({
      newBrandList: newBrandList,
      brandListTotal: brandListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCountryData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countryList = [];
    var countryListTotal = 0;
    let res = await CountryService.getCountry(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data ? res.data : [];
      countryListTotal = countryList ? countryList.length : 0;
    }
    const newCountryList =
      countryListTotal > 0
        ? countryList.map(({ slug, country_name }) => ({
            value: slug,
            label: country_name,
          }))
        : [];
    this.setState({
      countryList: countryList,
      newCountryList: newCountryList,
      countryListTotal: countryListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getStateData(countryValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var stateList = [];
    var stateListTotal = 0;
    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" +
        countryValue +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
      stateListTotal = stateList ? stateList.length : 0;
    }
    const newStateList =
      stateListTotal > 0
        ? stateList.map(({ slug, state_name }) => ({
            value: slug,
            label: state_name,
          }))
        : [];
    this.setState({
      stateList: stateList,
      newStateList: newStateList,
      stateListTotal: stateListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCountyData(value, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countyList = [];

    let res = await CountyService.getCounty(
      "is_dropdown=1&state_slug=" +
        value +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countyList = res.data ? res.data : [];
    }
    const newCountyList = countyList.map(({ slug, county_name }) => ({
      value: slug,
      label: county_name,
    }));
    this.setState({ countyList: countyList, newCountyList: newCountyList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCityData(stateValue, countyValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var cityList = [];
    var cityListTotal = 0;
    let res = await CityService.getCity(
      "is_dropdown=1&state_slug=" + stateValue + "&county_slug=" + countyValue,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    const newCityList =
      cityListTotal > 0
        ? cityList.map(({ slug, city_name }) => ({
            value: slug,
            label: city_name,
          }))
        : [];
    this.setState({
      cityList: cityList,
      newCityList: newCityList,
      cityListTotal: cityListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getAllRealetedData(slug, queryString = "") {
    this.setState({ showSpinner: false, loading: false });
    var resData = {};
    let res = await CityService.getDataByCitySlug(
      slug,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      resData.country_slug = res.data.countryData
        ? res.data.countryData.country.slug
        : "";
      resData.country_name = res.data.countryData
        ? res.data.countryData.country.country_name
        : "";
      var selectedCountryList = [];
      selectedCountryList = res.data.countryData.country
        ? [
            {
              value: res.data.countryData.country
                ? res.data.countryData.country.slug
                : "",
              label: res.data.countryData.country
                ? res.data.countryData.country.country_name
                : "",
            },
          ]
        : "";
      resData.county_slug = res.data.county ? res.data.county.slug : "";
      var selectedCountyList = [];
      selectedCountyList = res.data.county
        ? [
            {
              value: res.data.county.slug ? res.data.county.slug : "",
              label: res.data.county.county_name
                ? res.data.county.county_name
                : "",
            },
          ]
        : "";
      resData.state_slug = res.data.state ? res.data.state.slug : "";
      resData.state_name = res.data.state ? res.data.state.state_name : "";
      var selectedStateList = [];
      selectedStateList = res.data.state
        ? [
            {
              value: res.data.state.slug ? res.data.state.slug : "",
              label: res.data.state.state_name ? res.data.state.state_name : "",
            },
          ]
        : "";
    }
    this.setState(resData);
    this.setState({
      selectedStateList: selectedStateList,
      selectedCountyList: selectedCountyList,
      selectedCountryList: selectedCountryList,
    });
    this.setState({ showSpinner: false, loading: false });
  }
  // end by saurabh shukla

  async getAllCountryRealetedData(slug, queryString = "") {
    this.setState({ showSpinner: false, loading: false });
    var resData = {};
    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" + slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      var newStateList = [];
      res.data.forEach((item, i) => {
        newStateList.push({
          value: item.slug,
          label: item.state_name,
        });
      });
    }
    this.setState(resData);
    this.setState({
      newStateList: newStateList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getAllStateRealetedData(slug, queryString = "") {
    this.setState({ showSpinner: false, loading: false });
    var resData = {};
    let res = await CityService.getCity(
      "is_dropdown=1&state_slug=" + slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      var newCityList = [];
      res.data.forEach((item, i) => {
        newCityList.push({
          value: item.slug,
          label: item.city_name,
        });
      });
    }
    this.setState(resData);
    this.setState({
      newCityList: newCityList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async exportProperty() {
    this.setState({ showSpinner: true });
    let queryString = this.state.tableQueryString
      ? this.state.tableQueryString
      : this.state.customQueryString;
    let res = await PropertyService.exportProductData(
      "is_export=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      if (res.data.filePath !== undefined) {
        let a = document.createElement("a");
        a.href = res.data.filePath;
        a.download = res.data.fileName;
        a.click();
      }
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  customFilter() {
    let queryString =
      "brand_slug=" +
      this.state.brand_id +
      "&country_slug=" +
      this.state.country_slug +
      "&city_slug=" +
      this.state.city_slug +
      "&state_slug=" +
      this.state.state_slug;
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData("tab=" + this.state.tab + "&" + queryString);
  }

  resetData() {
    this.setState({
      brand_id: [],
      selectedBrandList: [],
    });
    this.setState({ country_slug: [], selectedCountryList: [] });
    this.setState({ county_slug: [] });
    this.setState({ city_slug: [], selectedCityList: [] });
    this.setState({ state_slug: [], selectedStateList: [] });
    this.getData("tab=" + this.state.tab);
    this.getBrandData();
    this.getCountryData();
    this.getStateData();
    this.getCityData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.getCountryData();
      this.getStateData();
      this.getCountyData();
      this.getCityData();
      this.getBrandData();
      this.setState({ showFilter: 1 });
    }
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getBrandData();
      this.getStateData();
      this.getCityData();
      this.setState({ isSearch: 1 });
    }
  };

  filterData() {
    let queryString =
      "property_name=" +
      (this.state.property_name ? this.state.property_name : "") +
      "&aka_name=" +
      (this.state.aka_name ? this.state.aka_name : "") +
      "&property_email=" +
      (this.state.property_email ? this.state.property_email : "") +
      "&property_phone_number=" +
      (this.state.property_phone_number
        ? this.state.property_phone_number
        : "") +
      "&brand_slug=" +
      (this.state.property_brand_id
        ? this.state.property_brand_id
        : this.state.brand_id
        ? this.state.brand_id
        : "") +
      "&property_manager=" +
      (this.state.property_manager_name
        ? this.state.property_manager_name
        : "") +
      "&city_slug=" +
      (this.state.city_slug ? this.state.city_slug : "") +
      "&state_slug=" +
      (this.state.state_slug ? this.state.state_slug : "") +
      "&zipcode=" +
      (this.state.zipcode ? this.state.zipcode : "") +
      "&status_id=" +
      (this.state.status_id ? this.state.status_id : "") +
      "&is_search=1";

    let moduleSearchString = "";
    moduleSearchString = this.state.property_name
      ? "Property Name - " + this.state.property_name
      : "";
    moduleSearchString = this.state.aka_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " Aka Name - " + this.state.aka_name
        : " Aka Name - " + this.state.aka_name
      : moduleSearchString;
    moduleSearchString = this.state.property_email
      ? moduleSearchString
        ? moduleSearchString + " ," + " Email - " + this.state.property_email
        : " Email - " + this.state.property_email
      : moduleSearchString;
    moduleSearchString = this.state.property_phone_number
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Phone Number - " +
          this.state.property_phone_number
        : " Phone Number - " + this.state.property_phone_number
      : moduleSearchString;
    moduleSearchString = this.state.property_brand_id
      ? " Brand - " + this.state.property_brand_id
      : this.state.brand_id
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Brand - " +
          this.state.selectedBrandList.label
        : " Brand - " + this.state.selectedBrandList.label
      : moduleSearchString;
    moduleSearchString = this.state.property_manager_name
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Property Manager - " +
          this.state.property_manager_name
        : " Property Manager - " + this.state.property_manager_name
      : moduleSearchString;
    moduleSearchString = this.state.city_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " City - " + this.state.city_name
        : " City - " + this.state.city_name
      : moduleSearchString;
    moduleSearchString = this.state.state_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " State - " + this.state.state_name
        : " State - " + this.state.state_name
      : moduleSearchString;
    moduleSearchString = this.state.zipcode
      ? moduleSearchString
        ? moduleSearchString + " ," + " Zip Code - " + this.state.zipcode
        : " Zip Code - " + this.state.zipcode
      : moduleSearchString;
    moduleSearchString = this.state.status_id
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Status - " +
          this.state.selectedStatusList.label
        : " Status - " + this.state.selectedStatusList.label
      : moduleSearchString;

    if (
      this.state.property_name ||
      this.state.aka_name ||
      this.state.property_email ||
      this.state.property_phone_number ||
      this.state.brand_id ||
      this.state.property_brand_id ||
      this.state.property_manager_name ||
      this.state.city_slug ||
      this.state.state_slug ||
      this.state.zipcode ||
      this.state.status_id
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    if (event.target.name === "country_slug") {
      if (event.target.value === "") {
        this.setState({
          state_slug: "",
          city_slug: "",
          stateList: [],
          cityList: [],
        });
      } else {
        this.getStateData(event.target.value);
      }
    }
    if (event.target.name === "state_slug") {
      if (event.target.value === "") {
        this.setState({
          city_slug: "",
          cityList: [],
        });
      } else {
        this.getCityData(event.target.value);
      }
    }

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleChangeStatusList(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
    });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData("tab=" + this.state.tab + "&" + queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  rowClickedHandler = (e, slug) => {
    this.getOnboardingSetpsCount(slug);
  };

  handleChangeCityList(value) {
    let city_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        city_slug.push(item.value);
      });
    }
    if (this.state.isSearch !== 1) {
      this.getAllRealetedData(value.value);
    }
    this.setState({
      selectedCityList: value,
      city_slug: value.value,
      city_name: value.label,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeStateList(value) {
    let state_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        state_slug.push(item.value);
      });
    }
    if (this.state.isSearch !== 1) {
      this.getAllStateRealetedData(value.value);
    }
    this.setState({
      selectedStateList: value,
      state_slug: value.value,
      state_name: value.label,
      selectedCityListL: [],
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCountyList(value) {
    let county_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        county_slug.push(item.value);
      });
    }
    this.setState({
      selectedCountyList: value,
      county_slug: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCountryList(value) {
    let country_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        country_slug.push(item.value);
      });
    }

    this.getAllCountryRealetedData(value.value);
    this.setState({
      selectedCountryList: value,
      country_slug: value.value,
      selectedStateList: [],
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeBrandList(value) {
    let brand_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        brand_id.push(item.value);
      });
    }
    this.setState({
      selectedBrandList: value,
      brand_id: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChange = (file) => {
    this.setState({ file_url: file });
  };

  async confirmImport() {
    this.setState({ showSpinner: true, isSubmit: true });
    const fileData = new FormData();

    fileData.append("import_file", this.state.file_url);
    fileData.append("property_slug", "M4J1NFAG83P5");

    let res = await PropertyService.importProduct(fileData);
    if (global.successStatus.includes(res.status)) {
      this.setState({ importModal: false });
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.setState({ file_name: "", file_url: "" });
      this.getData();
    } else {
      this.setState({ importModal: false });
      if (
        res.data.result.errorDetail !== null &&
        res.data.result.errorDetail !== undefined
      ) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.message ? res.data.message : "Error!",
      });
    }
    this.setState({ showSpinner: false, isSubmit: false, show: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  confirmImportModal() {
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        <div>
          <main>
            <div className="brandName text-center">
              <h4>OHAI</h4>
            </div>

            {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
            <Container>
              <div className="row row-xs">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12">
                  <div className="brandSection">
                    <div className="dashbaord-pageHeadertitle d-flex flex-wrap justify-content-between align-items-center">
                      <h3>Properties</h3>
                      <div className="d-flex flex-wrap">
                        {global.userPermissions.checkPermission(
                          "property-add"
                        ) && (
                          <Link to={"/properties/add"}>
                            <button className="btn-success-outline mr-3">
                              <img src={pluswIcon} /> Add
                            </button>
                          </Link>
                        )}

                        <button
                          onClick={this.searchToggle}
                          className="btn-success-outline-small"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </button>
                      </div>
                    </div>

                    {this.state.showFilter ? (
                      <>
                        <Row className="align-items-center order_form pt-0 pd-xs-l-0 pd-xs-r-0">
                          <Col
                            md={3}
                            lg={3}
                            xl={3}
                            className="mb-md-0 mb-3 pl-0 pd-xs-t-30 pd-lg-t-0"
                          >
                            <label className="form-label text-left pd-b-5">
                              Brand
                            </label>
                            <Select
                              styles={customStyles}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedBrandList}
                              options={this.state.newBrandList}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeBrandList(value)
                              }
                              defaultValue={this.state.selectedBrandList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </Col>
                          <Col
                            md={3}
                            lg={3}
                            xl={3}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <label className="form-label text-left pd-b-5">
                              Country
                            </label>
                            <Select
                              styles={customStyles}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedCountryList}
                              options={this.state.newCountryList}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeCountryList(value)
                              }
                              defaultValue={this.state.selectedCountryList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </Col>

                          <Col
                            md={3}
                            lg={3}
                            xl={3}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <label className="form-label text-left pd-b-5">
                              State
                            </label>
                            <Select
                              styles={customStyles}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedStateList}
                              options={this.state.newStateList}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeStateList(value)
                              }
                              defaultValue={this.state.selectedStateList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </Col>
                          <Col
                            md={3}
                            lg={3}
                            xl={3}
                            className="mb-md-0 mb-3 pr-0 pl-0"
                          >
                            <label className="form-label text-left pd-b-5">
                              City
                            </label>
                            <Select
                              styles={customStyles}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedCityList}
                              options={this.state.newCityList}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeCityList(value)
                              }
                              defaultValue={this.state.selectedCityList}
                              autoComplete="off"
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </Col>
                          <Col md={12} lg={12} xl={12}>
                            <Row className="mt-3 justify-content-end">
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-0 mg-r-20"
                              >
                                Apply
                              </button>
                              <button
                                onClick={this.resetData}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </Container>
            <Container>
              {queryParams.isGlobal === "1" ? (
                <>
                  <div className="col-md-12 pd-l-30 pd-r-30 mg-t-30 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                    {"Search applied on: " +
                      queryParams.global_col_label +
                      " - " +
                      queryParams.global_search}
                    <button
                      onClick={this.clearFilter}
                      className="mg-l-15 btn-success-outline-small"
                    >
                      Clear
                    </button>
                  </div>
                </>
              ) : (
                ""
              )}
              {this.state.is_search === 1 ? (
                <>
                  <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel justify_mob_filter mg-t-15">
                    <div className="custm-filter pd-lg-t-5 pd-xs-t-15">
                      <div>
                        {" "}
                        <label className="form-label text-left pd-b-2 mb-0">
                          Search Applied On
                        </label>
                      </div>
                      <div className="filter_bg_cust">
                        {this.state.module_search.map((item, i) => {
                          return <span key={i}>{item}</span>;
                        })}
                        <button
                          onClick={this.clearFilter}
                          className="btn-fliter-clear"
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="scrolling-carousel pd-xs-l-0 pd-l-0 scroll-slide">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="all"
                  onSelect={(e) => this.getActiveClass(e)}
                  eventKey={this.state.activeClass}
                  className="pd-r-60"
                >
                  <ScrollingCarousel
                    rightArrow={true}
                    leftArrow={true}
                    className="swipesection pd-t-20"
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="activeTab"
                        id="activeTab"
                        className={
                          this.state.activeClass === "activeTab" ? "active" : ""
                        }
                      >
                        Active
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        eventKey="inactiveTab"
                        id="inactiveTab"
                        className={
                          this.state.activeClass === "inactiveTab"
                            ? "active"
                            : ""
                        }
                      >
                        Inactive
                      </Nav.Link>
                    </Nav.Item>
                  </ScrollingCarousel>
                  <Tab.Content>
                    <Tab.Pane eventKey="activeTab">
                      <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                        {this.state.total_record > 0 ||
                        this.state.filter_value !== "" ? (
                          <div className="mg-t-20 brandList">
                            <ReactDatatable
                              className="table no-wrap mg-b-30 pd-b-2 propertytable"
                              config={
                                this.state.total_record > global.page_size
                                  ? this.state.config
                                  : this.state.config
                              }
                              records={this.state.records}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              // loading={this.state.loading}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                              // onRowClicked={this.rowClickedHandler}
                            />
                          </div>
                        ) : (
                          <NoData
                            msg={global.commonMessages.checkMessage(
                              "ACTIVE_PROPERTIES"
                            )}
                          />
                        )}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="inactiveTab">
                      <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                        {this.state.total_record > 0 ||
                        this.state.filter_value !== "" ? (
                          <div className="mg-t-20 brandList">
                            <ReactDatatable
                              className="table no-wrap mg-b-30 pd-b-2 propertytable"
                              config={
                                this.state.total_record > global.page_size
                                  ? this.state.config
                                  : this.state.config
                              }
                              records={this.state.records}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              // loading={this.state.loading}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                              // onRowClicked={this.rowClickedHandler}
                            />
                          </div>
                        ) : (
                          <NoData
                            msg={global.commonMessages.checkMessage(
                              "INACTIVE_PROPERTIES"
                            )}
                          />
                        )}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Container>
          </main>
        </div>

        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Properties</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Property Name/DBA</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="property_name"
                      value={this.state.property_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Property Manger</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="property_manager_name"
                      value={this.state.property_manager_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Brand</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      value={this.state.selectedBrandList}
                      options={this.state.newBrandList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeBrandList(value)}
                      defaultValue={this.state.selectedBrandList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>City</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedCityList}
                      options={this.state.newCityList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeCityList(value)}
                      defaultValue={this.state.selectedCityList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Zip Code</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="zipcode"
                      value={this.state.zipcode}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Property SKU</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="aka_name"
                      value={this.state.aka_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Email</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="property_email"
                      value={this.state.property_email}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Phone</label>
                  <div className="col-md-12 p-0">
                    <PhoneInput
                      country={global.country}
                      disableSearchIcon
                      // autoFormat= "true"
                      enableSearch="true"
                      countryCodeEditable="true"
                      value={this.state.property_phone_number}
                      onChange={this.onChangePhone}
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>State</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedStateList}
                      options={this.state.newStateList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeStateList(value)}
                      defaultValue={this.state.selectedStateList}
                      autoComplete="off"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                {/* <InputGroup className="mt-3">
                  <label>Status</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedStatusList}
                      options={this.state.newStatusList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeStatusList(value)}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup> */}
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* import data modal */}
        <Modal show={this.state.importModal} className="modal-xs" centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeConfirmImportModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Import</h3>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="d-flex justify-content-between mt-0 row">
                  <form name="myFile">
                    <InputGroup className="mt-0">
                      <label> Select file you want to import : </label>
                      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-12 mg-t-5 mg-md-t-0 pd-l-0 pd-r-0 mg-b-15">
                        <Form.Control
                          onChange={(e) => this.changeFile(e)}
                          name="importFile"
                          className="form-control max_width_100"
                          type="file"
                        />
                      </div>
                    </InputGroup>

                    <FileUploader
                      onChange={this.changeHandler}
                      handleChange={this.handleChange}
                      name="file_name"
                      types={["csv", "xlsx", "xls", "ods"]}
                    />

                    <div className="d-flex justify-content-between mt-3 ">
                      <button
                        type="button"
                        className="mx-auto m-0 btn btn-outline-light btn-block"
                        onClick={() => this.closeConfirmImportModal()}
                      >
                        Cancel
                      </button>

                      <button
                        type="button"
                        className="mx-auto m-0 btn btn btn-success btn-block"
                        onClick={this.confirmImport}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit ? global.loader : "Import"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.notesPopup} centered className="modal-large">
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="">
              <span
                className="welcomeClose"
                onClick={() => this.closeNotesPopup()}
              >
                {global.closee}
              </span>
              <h3 className="text-center">Property Notes</h3>
              <div>
                <p className="media-body p_wrap1">
                  {this.state.property_notes ? this.state.property_notes : ""}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Properties;
