////////////////////////////////////////////////////////////
//     							                                      //
//  Program: LisitingFees.jsx                             //
//  Application: Lisiting Fees                            //
//  Option: List all LisitingFees                         //
//  Developer: Np						                              //
//  Date: 2022-02-16                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import FileUploadService from "../../services/FileUploadService";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import renderHTML from "react-render-html";
import NoData from "../common/NoData";
import JoditEditor from "jodit-react";
import { Jodit } from "jodit";
import ListingService from "../../services/ListingService";

export class ListingArrivalInstruction extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      open: global.showOpen,
      eventKey: "",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      listing_slug: this.props.listing_slug,
      property_slug: this.props.property_slug,
      isSubmit: false,

      pathname: window.location.pathname,

      descShowRules: 0,
      descShowRules1: 0,
    };

    this.fullTextRules = this.fullTextRules.bind(this);
    this.fullTextRules1 = this.fullTextRules1.bind(this);
    this.setContent = this.setContent.bind(this);
    this.setContent1 = this.setContent1.bind(this);
    this.editorConfig = {
      extraButtons: ["uploadImage", "uploadImageVideo"],
    };
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.uploadImageButton();
    this.uploadVideoButton();
    this.fetchListingData();
  }

  async fetchListingData() {
    if (this.props.listing_slug !== undefined) {
      var resData = {};
      let res = await ListingService.getListing(this.props.listing_slug);
      if (global.successStatus.includes(res.status)) {
        resData.arrival_instruction_loqron = res.data.arrival_instruction_loqron
          ? res.data.arrival_instruction_loqron
          : "";
        resData.loqron_description = res.data.loqron_description
          ? res.data.loqron_description
          : "";
      }
      this.setState(resData);
    }
  }

  setContent(value) {
    this.setState({
      arrival_instruction_loqron: value,
    });
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.validator.hideMessages();
  }

  setContent1(value) {
    this.setState({
      loqron_description: value,
    });
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.validator.hideMessages();
  }

  changeView = () => {
    this.setState(
      (prevState) => ({
        viewMode: prevState.viewMode === 1 ? 0 : 1,
        confirm_back: 0,
      }),
      () => {
        if (this.state.viewMode === 0) this.fetchListingData();
        this.validator.hideMessages();
      }
    );
  };

  getTitle() {
    return "Price";
  }

  uploadImageButton = () => {
    Jodit.defaultOptions.controls.uploadImage = {
      name: "Upload image",
      // iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editor) => {
        await this.imageUpload(editor);
      },
    };
  };

  imageUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async function () {
      const imageFile = input.files[0];
      console.log("imageFile", imageFile);

      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", imageFile, imageFile.name);
      fileData.append("fileName", imageFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertImage(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertImage = (editor, url) => {
    const image = editor.selection.j.createInside.element("img");
    image.setAttribute("src", url);
    editor.selection.insertNode(image);
  };

  uploadVideoButton = () => {
    Jodit.defaultOptions.controls.uploadImageVideo = {
      name: "Upload video",
      // iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editor) => {
        await this.videoUpload(editor);
      },
    };
  };

  fullTextRules() {
    if (this.state.descShowRules === 0) {
      this.setState({ descShowRules: 1 });
    } else {
      this.setState({ descShowRules: 0 });
    }
  }

  fullTextRules1() {
    if (this.state.descShowRules1 === 0) {
      this.setState({ descShowRules1: 1 });
    } else {
      this.setState({ descShowRules1: 0 });
    }
  }

  saveOrUpdateDescription = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ showSpinner: true, isSubmit: true });

      let inputData = {
        tab: "arrival-instructions",
        arrival_instruction_loqron: this.state.arrival_instruction_loqron,
        loqron_description: this.state.loqron_description,
      };

      if (this.state.listing_slug !== undefined) {
        let res = await ListingService.updateArrivalDescription(
          this.state.listing_slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.fetchListingData();
          this.setState({ viewMode: 0 });
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      }
    }
  };

  render() {
    return (
      <>
        <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
            {this.state.viewMode === 0 &&
            global.userPermissions.checkPermission("single-unit-update") ? (
              <>
                <button
                  className="btn-success-outline-small ml-3 mr-3"
                  onClick={this.changeView}
                >
                  <img src={editIcon} alt="" />
                  Edit
                </button>
              </>
            ) : (
              <>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn-success-outline-small ml-3"
                    onClick={() => {
                      if (this.state.confirm_back === 1) {
                        if (
                          window.confirm("Do you want to exit without saving?")
                        ) {
                          this.changeView();
                        } else {
                          return false;
                        }
                      } else {
                        this.changeView();
                      }
                    }}
                  >
                    Cancel
                  </button>

                  {global.userPermissions.checkPermission(
                    "single-unit-update"
                  ) ? (
                    <button
                      className="btn-success  mr-3"
                      onClick={this.saveOrUpdateDescription}
                    >
                      {this.state.isSubmit ? global.loader : "Save"}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>
          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0  mg-t-15">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
          {this.state.viewMode === 0 ? (
            <div className="row m-0 listing_fee_price">
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 ">
                <h3 className="mg-l-0 background-head">Arrival Instructions</h3>
              </div>
              <div className="row col-md-12 mg-lg-t-0 mg-xs-t-10 mg-md-t-0 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 border-bottom-0">
                {this.state.arrival_instruction_loqron ? (
                  <>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pd-l-30 pd-r-30 pl-0 listing_detail">
                      {/* <p className="az-profile-name-text pl-0">
                        Arrival Instructions:
                      </p> */}
                      <div className="para">
                        {this.state.arrival_instruction_loqron ? ( //check if property_rules is exist
                          this.state.arrival_instruction_loqron.length >
                          global.showMoreLength ? ( //check if length is long
                            this.state.descShowRules === 0 ? (
                              <>
                                <HTMLEllipsis
                                  unsafeHTML={
                                    this.state.arrival_instruction_loqron
                                      ? this.state.arrival_instruction_loqron
                                      : ""
                                  }
                                  maxLine="6"
                                  ellipsis=""
                                  trimright="true"
                                  basedOn="letters"
                                />
                                <p
                                  className="hand-cursor showmore"
                                  onClick={this.fullTextRules}
                                >
                                  Show More
                                </p>
                              </>
                            ) : (
                              <>
                                {renderHTML(
                                  "" + this.state.arrival_instruction_loqron
                                )}
                                <p
                                  className="hand-cursor showmore"
                                  onClick={this.fullTextRules}
                                >
                                  Show Less
                                </p>
                              </>
                            )
                          ) : (
                            renderHTML(
                              "" + this.state.arrival_instruction_loqron
                            )
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <NoData msg="Arrival Instructions" action=" Edit " />
                )}
              </div>
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 ">
                <h3 className="mg-l-0 background-head">LOQRON Description</h3>
              </div>
              <div className="row col-md-12 mg-lg-t-0 mg-xs-t-10 mg-md-t-0 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 border-bottom-0">
                {this.state.loqron_description ? (
                  <>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pd-l-30 pd-r-30 pl-0 listing_detail">
                    
                      <div className="para">
                        {this.state.loqron_description ? ( //check if property_rules is exist
                          this.state.loqron_description.length >
                          global.showMoreLength ? ( //check if length is long
                            this.state.descShowRules1 === 0 ? (
                              <>
                                <HTMLEllipsis
                                  unsafeHTML={
                                    this.state.loqron_description
                                      ? this.state.loqron_description
                                      : ""
                                  }
                                  maxLine="6"
                                  ellipsis=""
                                  trimright="true"
                                  basedOn="letters"
                                />
                                <p
                                  className="hand-cursor showmore"
                                  onClick={this.fullTextRules1}
                                >
                                  Show More
                                </p>
                              </>
                            ) : (
                              <>
                                {renderHTML(
                                  "" + this.state.loqron_description
                                )}
                                <p
                                  className="hand-cursor showmore"
                                  onClick={this.fullTextRules}
                                >
                                  Show Less
                                </p>
                              </>
                            )
                          ) : (
                            renderHTML(
                              "" + this.state.loqron_description
                            )
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <NoData msg="LOQRON Description" action=" Edit " />
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {this.state.viewMode === 1 ? (
            <div className="m-0 listing_fee_price">
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 background-head">Arrival Instructions</h3>
              </div>
              <div className="row align-items-center pd-lg-b-15 pd-xs-b-0 border-bottom-0 pd-lg-r-15">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">
                    [Parking-Spot]{" "}
                    {this.state.lock_type === 1
                      ? "[Key-Cafe-Code]"
                      : this.state.lock_type === 2
                      ? "[Latch-Door-Code] [Latch-Laundry-Code]"
                      : ""}
                    [Wifi-Name] [Wifi-Password] [Unit-Number] [Address]
                  </label>
                  <JoditEditor
                    config={this.editorConfig}
                    ref={null}
                    name="arrival_instruction_loqron"
                    id="arrival_instruction_loqron"
                    value={this.state.arrival_instruction_loqron}
                    onBlur={(newContent) => this.setContent(newContent)}
                  />
                  {this.state.errors.arrival_instruction_loqron ? (
                    <div className="text-danger">
                      {"The arrival instructions is required."}
                    </div>
                  ) : (
                    this.validator.message(
                      "arrival instructions",
                      this.state.arrival_instruction_loqron,
                      "max:100000",
                      { className: "text-danger" }
                    )
                  )}
                </div>

                <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <h3 className="mg-l-0 background-head">LOQRON Description</h3>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <JoditEditor
                    config={this.editorConfig}
                    ref={null}
                    name="loqron_description"
                    id="loqron_description"
                    value={this.state.loqron_description}
                    onBlur={(newContent) => this.setContent1(newContent)}
                  />
                  {this.state.errors.loqron_description ? (
                    <div className="text-danger">
                      {this.state.errors.loqron_description}
                    </div>
                  ) : (
                    this.validator.message(
                      "loqron_description",
                      this.state.loqron_description,
                      "max:100000",
                      { className: "text-danger" }
                    )
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default ListingArrivalInstruction;
