////////////////////////////////////////////////////////////
//     							                          //
//  Program: ListingView.jsx                         //
//  Application: ListingView                             //
//  Option: For view Listing Viewd etails                //
//  Developer: Ashish Kumar 				              //
//  Date: 2022-02-09                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import ListingService from "../../services/ListingService";
import Info from "./Info";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import roomImage from "./../../assets/images/room_placeholder.png";
import ListingImages from "./ListingImages";
import ListingFees from "./ListingFees";
import ListingAmenities from "./ListingAmenities";
// import ListingSpecialPrice from "./ListingSpecialPrice";
import ListingUnavailableDates from "./ListingUnavailableDates";
import ListingArrivalInstruction from "./ListingArrivalInstruction";
import Onboarding from "./Onboarding";
import Preview from "./Preview";
import ListingRentalActivity from "./ListingRentalActivity";
import ListingPetFee from "./ListingPetFee";
// import editIcon from "./../../assets/images/edit-pen-icon.svg";
// import { Link } from "react-router-dom";
// import deleteicon from "../../assets/images/delete.svg";
import queryString from "query-string";
import LisitingSidebar from "../common/LisitingSidebar";
import AllPropertyLisitingSidebar from "../common/AllPropertyLisitingSidebar";
import PropertyService from "../../services/PropertyService";
import KeycafeService from "../../services/KeycafeService";

let queryParams = queryString.parse(window.location.search);

export class ListingView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleSwitchDark2: true,
      addModal: false,
      showSpinner: false,
      loading: false,
      editModal: false,
      deleteModal: false,
      status_id: 1,
      open: true,
      eventKey: "3",
      confirm_back: 0,
      listing_slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      outside_property_slug: this.props.match.params.outsidePropertySlug,
      pathname: window.location.pathname,
      imageUrl: roomImage,
      activeClass: "info",
      isRefresh: 0,
      listing_name: "",
      lock_type: 0,
      LOQRON_enable: 0,
      LOQRON_listing: 0,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.getConfirmStatus = this.getConfirmStatus.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.getImgStatus = this.getImgStatus.bind(this);
  }

  async componentDidMount() {
    this.getData();
    this.getKeyCafeFlag();

    if (queryParams.request_from === "cleaning") {
      document.getElementById("unavailableDates").click();
    }

    if (queryParams.request_from === "activity_stream") {
      document.getElementById("Onboarding").click();
    }
    if (this.state.listing_slug !== undefined) {
      var resData = {};
      let res = await ListingService.getListing(this.state.listing_slug);
      if (global.successStatus.includes(res.status)) {
        sessionStorage.setItem(
          "property_slug",
          res.data.property ? res.data.property.slug : ""
        );
        sessionStorage.setItem(
          "property_name",
          res.data.property ? res.data.property.property_name : ""
        );

        localStorage.setItem("property_slug", res.data.property.slug);
        localStorage.setItem("property_name", res.data.property.property_name);

        this.props.propertyName(
          res.data.property && res.data.property.property_name
            ? res.data.property.property_name
            : "Property"
        );
        this.props.propertySlug(
          res.data.property && res.data.property.slug
            ? res.data.property.slug
            : ""
        );

        // resData = res.data ? res.data : {};
        resData.imageUrl = res.data.room_main_image_url
          ? res.data.room_main_image_url
          : "";
        resData.unit_slug = res.data.unit ? res.data.unit.slug : "";
        resData.floor_plan_slug = res.data.unit
          ? res.data.unit.floor_plan
            ? res.data.unit.floor_plan.slug
            : ""
          : "";
        resData.unit_name = res.data.unit ? res.data.unit.unit_name : "";
        resData.room_category_slug = res.data.room_category
          ? res.data.room_category.slug
          : "";
        resData.room_type_slug = res.data.room_type
          ? res.data.room_type.slug
          : "";
        resData.listing_name = res.data.listing_name
          ? res.data.listing_name
          : "";
        resData.pet_allowed =
          res.data && res.data.property ? res.data.property.pet_allowed : "";
        resData.listing_id =
          res.data && res.data.listing_unit_id ? res.data.listing_unit_id : "";
      }
      this.setState(resData);
    }
  }

  async getKeyCafeFlag() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await KeycafeService.getKeycafeSetting(
      "property_slug=" + this.state.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      resData.LOQRON_enable = res.data ? res.data.LOQRON_enable : 0;
      resData.LOQRON_listing = res.data ? res.data.LOQRON_listing : 0;
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData.lock_type =
          res.data && res.data.lock_type ? res.data.lock_type : 0;
      }

      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  getTitle() {
    return (
      "Listings" +
      (this.state.listing_name ? " : " + this.state.listing_name : "")
    );
  }

  getConfirmStatus(confirmBack) {
    this.setState({ confirm_back: confirmBack });
  }

  getImgStatus(imgFile) {
    this.setState({ imageUrl: imgFile });
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }
  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "listingImages") {
      this.setState({ isRefresh: 2 });
    } else if (e === "listingAmenities") {
      this.setState({ isRefresh: 3 });
    } else if (e === "listingPrice") {
      this.setState({ isRefresh: 4 });
    } else if (e === "listingPreview") {
      this.setState({ isRefresh: 5 });
    } else if (e === "listingPetFee") {
      this.setState({ isRefresh: 6 });
    } else if (e === "unavailableDates") {
      this.setState({ isRefresh: 7 });
    } else if (e === "arrivalInstruction") {
      this.setState({ isRefresh: 10 });
    } else if (e === "listingActivity") {
      this.setState({ isRefresh: 8 });
    } else if (e === "Onboarding") {
      this.setState({ isRefresh: 0 });
      setTimeout(() => {
        this.setState({ isRefresh: 9 });
      }, 10);
    } else {
      this.setState({ isRefresh: 0 });
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-sm-l-15 pd-lg-l-0 position-relative">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <LisitingSidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyLisitingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-r-0 pd-sm-l-0 pd-lg-l-30">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 swipersectionBtns info_box_model">
                    <h3>{this.getTitle()}</h3>
                    {/* <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                                {this.props.listing_slug !== undefined ? (
                                  <>
                                    <span
                                      onClick={() => {
                                        this.opendeleteListingModal(this.props.listing_slug);
                                      }}
                                      className="deleteicon mg-r-20"
                                    >
                                      <img src={deleteicon} />
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}{" "}
                                <Link
                                  to={
                                    "/properties/view/" +
                                    this.props.propertySlug +
                                    "/listing/update/" +
                                    this.props.listing_slug
                                  }
                                >
                                  <button className="btn-success-outline-small">
                                     
                                    Edit
                                  </button>
                                </Link>
                              </div> */}
                  </div>
                </div>
                <div className="scrolling-carousel pd-lg-l-30 listingView">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="info"
                    onSelect={(e) => this.getActiveClass(e)}
                    eventKey={this.state.activeClass}
                    className="pd-r-60"
                  >
                    <ScrollingCarousel
                      rightArrow={true}
                      leftArrow={true}
                      className="swipesection scroll_nav_mob"
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="info"
                          id="infoForm"
                          className={
                            this.state.activeClass === "info" ? "active" : ""
                          }
                        >
                          Info
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="listingImages"
                          id="lisitngImages"
                          className={
                            this.state.activeClass === "listingImages"
                              ? "active"
                              : ""
                          }
                        >
                          Images
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="listingAmenities"
                          id="listingAmenities"
                          className={
                            this.state.activeClass === "listingAmenities"
                              ? "active"
                              : ""
                          }
                        >
                          Amenities
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="listingPrice"
                          id="listingPrice"
                          className={
                            this.state.activeClass === "listingPrice"
                              ? "active"
                              : ""
                          }
                        >
                          Price
                        </Nav.Link>
                      </Nav.Item>
                      {this.state.pet_allowed === 1 ? (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="listingPetFee"
                            id="listingPetFee"
                            className={
                              this.state.activeClass === "listingPetFee"
                                ? "active"
                                : ""
                            }
                          >
                            Pet Fees
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}
                      {/* <Nav.Item>
                        <Nav.Link
                          eventKey="specialPrice"
                          id="specialPrice"
                          className={
                            this.state.activeClass === "specialPrice"
                              ? "active"
                              : ""
                          }
                        >
                          Custom Pricing
                        </Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link
                          eventKey="unavailableDates"
                          id="unavailableDates"
                          className={
                            this.state.activeClass === "unavailableDates"
                              ? "active"
                              : ""
                          }
                        >
                          Resident Stay
                        </Nav.Link>
                      </Nav.Item>
                      {this.state.lock_type === 1 &&
                        this.state.listing_slug !== undefined &&
                        this.state.LOQRON_enable === 1 &&
                        this.state.listing_id === this.state.LOQRON_listing && (
                          <Nav.Item>
                            <Nav.Link
                              eventKey="arrivalInstruction"
                              id="arrivalInstruction"
                              className={
                                this.state.activeClass === "arrivalInstruction"
                                  ? "active"
                                  : ""
                              }
                            >
                              Arrival Instructions
                            </Nav.Link>
                          </Nav.Item>
                        )}
                      <Nav.Item>
                        <Nav.Link
                          eventKey="listingPreview"
                          id="listingPreview"
                          className={
                            this.state.activeClass === "listingPreview"
                              ? "active"
                              : ""
                          }
                        >
                          Preview
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="listingActivity"
                          id="listingActivity"
                          className={
                            this.state.activeClass === "listingActivity"
                              ? "active"
                              : ""
                          }
                        >
                          Sync
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="Onboarding"
                          id="Onboarding"
                          className={
                            this.state.activeClass === "Onboarding"
                              ? "active"
                              : ""
                          }
                        >
                          Activity Stream
                        </Nav.Link>
                      </Nav.Item>
                    </ScrollingCarousel>
                    <Tab.Content>
                      <Tab.Pane eventKey="info">
                        <Info
                          listing_slug={this.state.listing_slug}
                          propertySlug={this.state.property_slug}
                          unit_slug={this.state.unit_slug}
                          floor_plan_slug={this.state.floor_plan_slug}
                          imgFile={this.state.imageUrl}
                          propPropertySlug={
                            this.props.match.params.propertySlug
                          }
                          outside_property_slug={
                            this.state.outside_property_slug
                          }
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="listingImages">
                        {this.state.isRefresh === 2 && (
                          <ListingImages
                            listing_slug={this.state.listing_slug}
                            property_slug={this.state.property_slug}
                            unit_slug={this.state.unit_slug}
                            floor_plan_slug={this.state.floor_plan_slug}
                            imgFile={this.getImgStatus}
                            propPropertySlug={
                              this.props.match.params.propertySlug
                            }
                            outside_property_slug={
                              this.state.outside_property_slug
                            }
                          />
                        )}
                      </Tab.Pane>

                      <Tab.Pane eventKey="listingAmenities">
                        {this.state.isRefresh === 3 && (
                          <ListingAmenities
                            listing_slug={this.state.listing_slug}
                            property_slug={this.state.property_slug}
                            unit_slug={this.state.unit_slug}
                            floor_plan_slug={this.state.floor_plan_slug}
                            confirmBackStatus={this.getConfirmStatus}
                            propPropertySlug={
                              this.props.match.params.propertySlug
                            }
                            outside_property_slug={
                              this.state.outside_property_slug
                            }
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="listingPrice">
                        {this.state.isRefresh === 4 && (
                          <ListingFees
                            listing_slug={this.state.listing_slug}
                            property_slug={this.state.property_slug}
                            unit_slug={this.state.unit_slug}
                            floor_plan_slug={this.state.floor_plan_slug}
                            confirmBackStatus={this.getConfirmStatus}
                            propPropertySlug={
                              this.props.match.params.propertySlug
                            }
                            outside_property_slug={
                              this.state.outside_property_slug
                            }
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="listingPetFee">
                        {this.state.isRefresh === 6 && (
                          <ListingPetFee
                            listing_slug={this.state.listing_slug}
                            property_slug={this.state.property_slug}
                            unit_slug={this.state.unit_slug}
                            floor_plan_slug={this.state.floor_plan_slug}
                            confirmBackStatus={this.getConfirmStatus}
                            propPropertySlug={
                              this.props.match.params.propertySlug
                            }
                            outside_property_slug={
                              this.state.outside_property_slug
                            }
                          />
                        )}
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="specialPrice">
                        {this.state.isRefresh === 6 && (
                          <ListingSpecialPrice
                            listing_slug={this.state.listing_slug}
                            unit_slug={this.state.unit_slug}
                            property_slug={this.state.property_slug}
                            confirmBackStatus={this.getConfirmStatus}
                          />
                        )}
                      </Tab.Pane> */}
                      <Tab.Pane eventKey="unavailableDates">
                        {this.state.isRefresh === 7 && (
                          <ListingUnavailableDates
                            listing_slug={this.state.listing_slug}
                            property_slug={this.state.property_slug}
                            unit_slug={this.state.unit_slug}
                            floor_plan_slug={this.state.floor_plan_slug}
                            confirmBackStatus={this.getConfirmStatus}
                            propPropertySlug={
                              this.props.match.params.propertySlug
                            }
                            outside_property_slug={
                              this.state.outside_property_slug
                            }
                          />
                        )}
                      </Tab.Pane>
                      {this.state.lock_type === 1 &&
                        this.state.listing_slug !== undefined &&
                        this.state.LOQRON_enable === 1 &&
                        this.state.listing_id === this.state.LOQRON_listing && (
                          <Tab.Pane eventKey="arrivalInstruction">
                            {this.state.isRefresh === 10 && (
                              <ListingArrivalInstruction
                                listing_slug={this.state.listing_slug}
                                property_slug={this.state.property_slug}
                                unit_slug={this.state.unit_slug}
                                floor_plan_slug={this.state.floor_plan_slug}
                                confirmBackStatus={this.getConfirmStatus}
                                propPropertySlug={
                                  this.props.match.params.propertySlug
                                }
                                outside_property_slug={
                                  this.state.outside_property_slug
                                }
                              />
                            )}
                          </Tab.Pane>
                        )}
                      <Tab.Pane eventKey="listingPreview">
                        {this.state.isRefresh === 5 && (
                          <Preview
                            listing_slug={this.state.listing_slug}
                            property_slug={this.state.property_slug}
                            unit_slug={this.state.unit_slug}
                            floor_plan_slug={this.state.floor_plan_slug}
                            confirmBackStatus={this.getConfirmStatus}
                            propPropertySlug={
                              this.props.match.params.propertySlug
                            }
                            outside_property_slug={
                              this.state.outside_property_slug
                            }
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="listingActivity">
                        {this.state.isRefresh === 8 && (
                          <ListingRentalActivity
                            listing_slug={this.state.listing_slug}
                            property_slug={this.state.property_slug}
                            unit_slug={this.state.unit_slug}
                            floor_plan_slug={this.state.floor_plan_slug}
                            confirmBackStatus={this.getConfirmStatus}
                            propPropertySlug={
                              this.props.match.params.propertySlug
                            }
                            outside_property_slug={
                              this.state.outside_property_slug
                            }
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="Onboarding">
                        {this.state.isRefresh === 9 && (
                          <Onboarding
                            listing_slug={this.state.listing_slug}
                            property_slug={this.state.property_slug}
                            unit_slug={this.state.unit_slug}
                            floor_plan_slug={this.state.floor_plan_slug}
                            confirmBackStatus={this.getConfirmStatus}
                            propPropertySlug={
                              this.props.match.params.propertySlug
                            }
                            outside_property_slug={
                              this.state.outside_property_slug
                            }
                          />
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default ListingView;
